
import "echarts/map/js/china"
import { nextTick } from "vue"
import echarts from "echarts"

const provinceList: Record<string, number[]> = {
  山东: [117.000923, 36.675807],
  河北: [115.48333, 38.03333],
  吉林: [125.35, 43.88333],
  黑龙江: [127.63333, 47.75],
  辽宁: [123.38333, 41.8],
  内蒙古: [111.670801, 41.818311],
  新疆: [87.68333, 43.76667],
  甘肃: [103.73333, 36.03333],
  宁夏: [106.26667, 37.46667],
  山西: [112.53333, 37.86667],
  陕西: [108.95, 34.26667],
  河南: [113.65, 34.76667],
  安徽: [117.283042, 31.86119],
  江苏: [119.78333, 32.05],
  浙江: [120.2, 30.26667],
  福建: [118.3, 26.08333],
  广东: [113.23333, 23.16667],
  江西: [115.9, 28.68333],
  海南: [110.35, 20.01667],
  广西: [108.320004, 22.82402],
  贵州: [106.71667, 26.56667],
  湖南: [113.0, 28.21667],
  湖北: [114.298572, 30.584355],
  四川: [104.06667, 30.66667],
  云南: [102.73333, 25.05],
  西藏: [91.0, 30.6],
  青海: [96.75, 36.56667],
  天津: [117.2, 39.13333],
  上海: [121.55333, 31.2],
  重庆: [106.45, 29.56667],
  北京: [116.41667, 39.91667],
  台湾: [121.3, 25.03],
  香港: [114.1, 22.2],
  澳门: [113.5, 22.2]
}

export default {
  setup: function() {
    let chartElement: HTMLDivElement
    let echartsDom: echarts.ECharts
    const init = () => {
      const keys = Object.keys(provinceList)
      const length = keys.length
      const amount = Math.round(Math.random() * (length / 4 - 4) + 4)
      const tempList = []
      for (let i = 0; i < amount; i++) {
        const index = Math.round(Math.random() * length)
        if (
          tempList.findIndex((item) => item.name === keys[index]) === -1 &&
          keys[index]
        ) {
          tempList.push({
            name: keys[index],
            value: provinceList[keys[index]]
          })
          if (tempList[i]?.value?.length === 2) {
            const number = Math.round(Math.random() * 7) || 1
            tempList[i]?.value?.push(number)
          }
        }
      }
      echartsDom.setOption(({
        tooltip: {
          trigger: "item",
          formatter: (params: any) => {
            return params.name + " : " + params.value[2]
          }
        },
        visualMap: {
          show: false,
          calculable: false,
          inRange: {
            color: ["rgba(107,204,249,1)", "rgba(107,250,185,1)"]
          },

          textStyle: {
            color: "#fff"
          }
        },
        geo: {
          map: "china",
          itemStyle: {
            areaColor: "rgba(31,59,106,.56)",
            borderColor: "rgba(255,255,255,.12)"
          },
          emphasis: {
            label: {
              show: false
            }
          }
        },
        series: [
          {
            type: "scatter",
            coordinateSystem: "geo",
            data: tempList.filter((it) => it.value.length === 3),
            symbolSize: 12,
            emphasis: {
              itemStyle: {
                borderColor: "#fff",
                borderWidth: 1
              }
            }
          }
        ]
      } as unknown) as echarts.EChartOption)
    }
    const setRef = (el: HTMLDivElement) => (chartElement = el)
    nextTick(() => {
      echartsDom = echarts.init(chartElement)
      init()
      setInterval(() => {
        init()
      }, 3000)
    })
    return { setRef }
  }
}
