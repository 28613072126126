
import ActiveDetail from "./components/ActiveDetail.vue"
import China from "./components/China.vue"
// import bigScreenHealth from "./components/bigScreenhealth.vue"
// import bigHealthRealTimeReport from "./components/bigHealthRealTimeReport.vue"
import {
  IFRAME_HOST,
  IFRAME_TWO_HOST,
  HYBRID_HOST
} from "@/assets/script/constant/config.constant"

import { Type, DataModel } from "./script/table-div"
import { SleepDataService } from "@/assets/script/service/sleep-data.service"
import { readonly, ref } from "vue"
import qs from "qs"
import { localGet } from "@/assets/script/utils/storage.util"
import { COUNT_VIEW_MODEL_KEY } from "@/assets/script/constant/config.constant"
import { ExpoConfigViewModel } from "@/assets/script/model/main.model"
import { format } from "@/assets/script/utils/date.util"
import { useRouter } from "vue-router"

function average(value: number[]) {
  let i
  let avg = 0
  let sum = 0
  for (i = 0; i < value.length; i++) {
    sum += value[i]
  }
  avg = sum / value.length
  return avg
}

function regression(xList: number[], yList: number[]) {
  const x = average(xList)
  const y = average(yList)

  let Lxx = 0
  let Lxy = 0
  for (let i = 0; i < xList.length; i++) {
    Lxx += (xList[i] - x) * (xList[i] - x)
    Lxy += (xList[i] - x) * (yList[i] - y)
  }

  const a = Lxy / Lxx
  const b = y - a * x
  return {
    a,
    b
  }
}

export default {
  components: {
    ActiveDetail, //活跃用户
    China
    // bigScreenHealth,
    // bigHealthRealTimeReport
  },
  setup() {
    const weChatUserList = ref([] as DataModel[][])
    const deviceList = ref([] as DataModel[][])
    const expoConfigViewModel = readonly(
      localGet(COUNT_VIEW_MODEL_KEY, new ExpoConfigViewModel())
    )
    SleepDataService.refreshUserAndDevice().then((result) => {
      weChatUserList.value =
        result.data?.weChatUserList.map((it) => [
          new DataModel(Type.IMAGE, it.headimgurl),
          new DataModel(Type.STRING, it.nickname),
          new DataModel(Type.STRING, it.attentionTime)
        ]) ?? []
    })

    const day = ref(format(new Date()))
    const time = ref(format(new Date(), "hh:mm"))

    const router = useRouter()

    const uid = router.currentRoute.value.query.uid || "" // 获取 phone 参数值，如果没有则默认为空字符串
    const deviceId = router.currentRoute.value.query.deviceId || "" // 获取 password 参数值，如果没有则默认为空字符串
    const healthId = router.currentRoute.value.query.healthId || "" // 获取 password 参数值，如果没有则默认为空字符串
    const sleepDeviceId = router.currentRoute.value.query.sleepDeviceId || "" // 获取 password 参数值，如果没有则默认为空字符串
    // 0 左侧 1 右侧
    const position = router.currentRoute.value.query.position || "" // 获取 password 参数值，如果没有则默认为空字符串

    // console.log("uid=" + uid)
    // console.log("deviceId=" + deviceId)
    // console.log("healthId=" + healthId)
    // console.log("sleepDeviceId=" + sleepDeviceId)
    // console.log("position=" + position)

    setInterval(() => {
      day.value = format(new Date())
      time.value = format(new Date(), "hh:mm")
    }, 1000)
    return {
      weChatUserList,
      deviceList,
      day,
      time,
      IFRAME_HOST,
      IFRAME_TWO_HOST,
      HYBRID_HOST,
      query: qs.stringify(
        {
          uid: uid,
          deviceId: deviceId,
          healthId: healthId,
          sleepDeviceId: sleepDeviceId,
          position: position
        },
        {
          arrayFormat: "repeat"
        }
      )
    }
  }
}
